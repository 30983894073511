 <template>
  <div style="height:100%">
    <div style="padding: 20px;height: 800px">
      <!-- 搜素框 -->
      <el-form :inline="true" :model="params" style="padding-top:20px;padding-left:20px;margin-bottom:20px;background-color:#fff;border-radius:8px" size="small">
        <el-form-item label="登录人姓名">
          <el-input v-model="params.userName" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="登录类型">
          <el-select v-model="params.type" placeholder="请选择登录类型">
            <el-option label="WEB端" value="1"></el-option>
            <el-option label="手机端" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录ip">
          <el-input v-model="params.ip" placeholder="请输入登录ip"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="checkData" type="primary" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height:82%;background-color:#fff;border-radius:8px;">
        <!-- <el-button type="primary" size="small" style="float:right;margin-top:20px;margin-right:20px;margin-bottom:20px" @click="add">新增</el-button> -->
        <el-table :data="tableData" stripe style="padding-top:40px;width: 96%;margin-left:15px" height="80%" size="small" :header-cell-style="{'background-color':'#84aace4a','color':'#343333','text-align':'center'}" :cell-style="{'text-align':'center'}">
          <el-table-column prop="account" label="登录用户账号"></el-table-column>
          <el-table-column prop="userName" label="登录人姓名"></el-table-column>
          <el-table-column prop="type" label="登录类型" :formatter="typeFormat"></el-table-column>
          <el-table-column prop="ip" label="登录ip"></el-table-column>
          <el-table-column prop="loginTime" label="登录时间" :formatter="dateFormat"></el-table-column>
        </el-table>
        <div style="padding-top:20px;height:40px;background-color:#fff;width: 100%;border-radius:8px;text-align:right">
          <el-pagination background :page-sizes="sizes" layout="total,sizes,prev,pager,next" :total="total" @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      tableData: [],
      params: {
        userName: "",
        type: "",
        ip:"",
        page: 1,
        size: 10,
      },
      total: 0,
      sizes: [10, 15],
    };
  },
  created() {
    this.queryLoginList();
  },
  methods: {
    queryLoginList(){//获取登录日志列表信息
      this.axios({
        method: "get",
        url: "/v1/web/login-history/list-user",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    typeFormat:function(row){
        if(row.type==1){
            return "WEB端"
        }else if(row.type==2){
            return "手机端"
        }
    },
    dateFormat:function(row){
      if(row.loginTime !== null && row.loginTime !== undefined){
        return moment(row.loginTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryLoginList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryLoginList();
    },
    checkData() {
      this.queryLoginList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.queryLoginList();
    },
  },
};
</script>

<style scoped>

</style>
